import React, { useState,useEffect } from 'react';
import { TextField, Button, Snackbar, Alert, Container, AppBar, Toolbar, IconButton, Typography, Grid, useTheme, InputAdornment, Paper, Box } from '@mui/material';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import translatedata from '../translate.json';

const Withdrawapprove = ({isLoggedIn, selectedLanguage}) => {
  const [confirm, setConfirm] = useState();
  const [message, setMessage] = useState('');
  const [stat, setStat] = useState('info'); // info, success, error
  const [openSnack, setOpenSnack] = useState(false);
  const [amount, setAmount] = useState(); // info, success, error
  const [account, setAccount] = useState();
  const query = new URLSearchParams(location.search);
  const hash = query.get('hash');
  const reqid = query.get('reqid');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnack(false);
  };
  useEffect(() => {

    axios.post("https://api.youbetshop.com/control-withdraw-payment", {  reqid: reqid })
      .then(response => {
        setAmount(response.data.data.amount)
        setAccount(response.data.data.account_no)
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].withdrawapprove.pleaselogin);
        setStat('error');
        setOpenSnack(true);
      });
  }, [isLoggedIn])

  const handleConfirm = () => {
    const token = cookies.serviceToken;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    axios.post("https://api.youbetshop.com/verify-withdraw-payment", { hash: hash, reqid: reqid,confirm:1 })
      .then(response => {
        if(response.data.success==true){
            setMessage(translatedata[selectedLanguage].withdrawapprove.success);
            setStat('success');
            setConfirm("true")
            setOpenSnack(true);
            setTimeout(() => {
              navigate('/')
            }, 2500);
        }else{
            setMessage(response.data.message);
            setStat('error');
            setConfirm("true")
            setOpenSnack(true);
            setTimeout(() => {
              navigate('/')
            }, 2500);  
        }

      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].withdrawapprove.error);
        setStat('error');
        setOpenSnack(true);
      });
  };
  const handleNotConfirm = () => {
    const token = cookies.serviceToken;
    const config = {
        headers: { Authorization: `Bearer ${token}` },
    };
    axios.post("https://api.youbetshop.com/verify-withdraw-payment", { hash: hash, reqid: reqid,confirm:2 },config)
      .then(response => {
        if(response.data.success==true){
        setMessage(translatedata[selectedLanguage].withdrawapprove.cancelwith);
        setStat('success');
        setConfirm("false")
        setOpenSnack(true);
        setTimeout(() => {
          navigate('/')
        }, 2500);
    }else{
        setMessage(response.data.message);
        setStat('error');
        setConfirm("true")
        setOpenSnack(true);
        setTimeout(() => {
          navigate('/')
        }, 2500);  
    }
      })
      .catch(error => {
        console.error('Hata', error);
        setMessage(translatedata[selectedLanguage].withdrawapprove.error);
        setStat('error');
        setOpenSnack(true);
      });
  };
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            <Typography variant="h6">
              {translatedata[selectedLanguage].withdrawapprove.home}
            </Typography>
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
          </Typography>
          <Typography variant="body1" style={{ marginRight: '10px' }}>
            {translatedata[selectedLanguage].withdrawapprove.withapp}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '40vh'
        }}
      >
        {!confirm?
        <Paper elevation={3} sx={{ padding: 4, maxWidth: 400, width: '100%' }}>
          <Box textAlign="center" marginBottom={2}>
            <Typography variant="h4" sx={{ color: "#333" }}>
            {translatedata[selectedLanguage].withdrawapprove.withapp}
            </Typography>
          </Box>
          
          <>
          <Typography sx={{ color: "#333" }}>
              {translatedata[selectedLanguage].withdrawapprove.amount}{amount}{translatedata[selectedLanguage].withdrawapprove.currency}
            </Typography>
            <Typography sx={{ color: "#333" }}>
            {translatedata[selectedLanguage].withdrawapprove.accountno}{account}
            </Typography>
          <Grid container spacing={2} sx={{marginTop:"10px"}}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                sx={{backgroundColor:"#009928"}}
                onClick={handleConfirm}
                fullWidth
              >
                {translatedata[selectedLanguage].withdrawapprove.approve}
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                sx={{backgroundColor:"#99001c"}}
                onClick={handleNotConfirm}
                fullWidth
              >
               {translatedata[selectedLanguage].withdrawapprove.cancel}
              </Button>
            </Grid>
          </Grid>
          </>
        </Paper>
        :
        confirm=="true"?          <Typography sx={{ color: "#333" }}>
        {translatedata[selectedLanguage].withdrawapprove.successprocess}
      </Typography>
      :          <Typography sx={{ color: "#333" }}>
       {translatedata[selectedLanguage].withdrawapprove.cancelprocess}
    </Typography>
        }
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={stat}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
      </Container>
    </>
  );
};

export default Withdrawapprove;
