import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


const Loading = () => {
  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
      <img src={"/image/logo.png"} style={{maxWidth:"150px"}}/>
      <CircularProgress />
    </Box>
  );
};

export default Loading;