import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountBalanceWallet from '@mui/icons-material/AccountBalanceWallet';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { Link } from 'react-router-dom';
import AttachMoney from '@mui/icons-material/AttachMoney';
import Person from '@mui/icons-material/Person';
import Support from '@mui/icons-material/Support';
import Login from '@mui/icons-material/Login';
import ReceiptIcon from '@mui/icons-material/Receipt';
import PersonAdd from '@mui/icons-material/PersonAdd';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme, Box, Grid, Badge,ButtonGroup } from '@mui/material';
import { useCookies } from 'react-cookie';
import moment from 'moment';
import LoginDialog from '../dialogs/LoginDialog';
import RegisterDialog from '../dialogs/RegisterDialog';
import TelegramIcon from '@mui/icons-material/Telegram';
import ForumIcon from '@mui/icons-material/Forum';
import { useNavigate } from 'react-router-dom';
import StarIcon from '@mui/icons-material/Star';
import axios from 'axios';
import CasinoIcon from '@mui/icons-material/Casino';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import translatedata from '../translate.json';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
const Header = ({ isLoggedIn, username,setTutorialOpen,setTutorialid, setIsLoggedIn, setUsername, balance, setBalance, isLoginOpen, setLoginOpen, blockbalance, promocodes, setNotify, notify, selectedLanguage, setSelectedLanguage }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRegisterOpen, setRegisterOpen] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['serviceToken']);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [languageAnchorEl, setLanguageAnchorEl] = useState(null);

  const currentDate = moment().format('YYYY-MM-DD HH:mm'); // 'LLL' formatını kullanarak güncel tarih ve saat
  const navigate = useNavigate();
  const handleTelegramClick = () => {
    window.open("https://t.me/youbetshop", "_blank");
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/447437586218", "_blank");
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:info@youbetshop.com";
  };
  const opentutorial = (id) => {
    setTutorialid(id);
  }
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLoginOpen = () => {
    setLoginOpen(true);
    handleClose();
  };

  const handleLoginClose = () => {
    setLoginOpen(false);
  };
  const handleLanguageMenuOpen = (event) => {
    setLanguageAnchorEl(event.currentTarget);
  };

  const handleLanguageMenuClose = () => {
    setLanguageAnchorEl(null);
  };

  const handleLanguageChange = (lang) => {
    setSelectedLanguage(lang);
    setLanguageAnchorEl(null);
    setCookie("lang", lang)
  };
  const handleRegisterOpen = () => {
    navigate("/register")
  };
  const formatCurrency = (value) => {
    return value?.toLocaleString('tr-TR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  };
  const handleRegisterClose = () => {
    setRegisterOpen(false);
  };
  const logout = () => {
    setIsLoggedIn(false);
    removeCookie("email");
    removeCookie("serviceToken");
  }
  const redirectforum = () => {
    const token = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const newWindow = window.open('', '_blank');

    axios.get("https://api.youbetshop.com/api/user/forum", config)
      .then(response => {
        const ftoken = response.data.token
        if (ftoken) {
          newWindow.location.href = "https://forumyoubetshop.com/dtoken-login?dtoken=" + ftoken;
        }

      }).catch(error => {
        console.error('Error with API requests:', error);
      });
  }
  return (
    <AppBar position="static" sx={{ backgroundColor: '#333', padding: 1 }}>
      <Toolbar sx={{ paddingRight: "0px", justifyContent: "space-between" }}>
        {/* <Typography variant="h6" sx={{ flexGrow: isLoggedIn?1.5:1 }}>
          MyApp
        </Typography> */}
        <img src={"image/logo.png"} onClick={() => navigate("/")} style={{ maxWidth: "80px", flexGrow: isLoggedIn ? 1.5 : 1 }} />
        {isMobile &&
          <>
            <Button
              variant="outlined"
              size="small"
              onClick={handleLanguageMenuOpen}
            >
              {selectedLanguage}
            </Button>
            <Menu
              anchorEl={languageAnchorEl}
              open={Boolean(languageAnchorEl)}
              onClose={handleLanguageMenuClose}
            >
              <MenuItem onClick={() => handleLanguageChange('en')}>EN</MenuItem>
              <MenuItem onClick={() => handleLanguageChange('tr')}>TR</MenuItem>
              <MenuItem onClick={() => handleLanguageChange('ar')}>AR</MenuItem>
              <MenuItem onClick={() => handleLanguageChange('de')}>DE</MenuItem>    
              <MenuItem onClick={() => handleLanguageChange('ru')}>RU</MenuItem>
              <MenuItem onClick={() => handleLanguageChange('es')}>ES</MenuItem>
            </Menu></>}
        {isMobile ? (
          <>                <Grid container sx={{ justifyContent: "end", maxWidth: isLoggedIn ? "220px" : "150px" }}>
            {isLoggedIn ?
              <>
                <Grid item xs={4} sm={4} md={4} lg={4}>

                  <Button
                    variant="contained"
                    size="small"
                    onClick={()=>opentutorial(16)}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      backgroundColor: "#cd7474"
                    }}
                  >
                    <Box>
                      <SafetyCheckIcon sx={{ fontSize: 24 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 11 }}>{formatCurrency(blockbalance)}</Typography>
                    </Box>
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4}>

                  <Button
                    component={Link} to="/deposit"
                    variant="contained"
                    size="small"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      backgroundColor: "#125f09"
                    }}
                  >
                    <Box>
                      <AccountBalanceWalletIcon sx={{ fontSize: 24 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 11 }}>{formatCurrency(balance)}</Typography>
                    </Box>
                  </Button>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleMenuOpen}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Box>
                      <AccountCircleIcon sx={{ fontSize: 24 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 11 }}>{translatedata[selectedLanguage].header.myaccount}</Typography>
                    </Box>
                  </Button>
                </Grid>
              </>
              :
              <>

                <Grid item xs={6} sm={6} md={6} lg={6}>

                  <Button
                    onClick={handleLoginOpen}
                    variant="contained"
                    size="small"
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      backgroundColor: "#125f09"
                    }}
                  >
                    <Box>
                      <AccountBalanceWalletIcon sx={{ fontSize: 24 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 11 }}>{translatedata[selectedLanguage].header.loginin}</Typography>
                    </Box>
                  </Button>
                </Grid>
                <Grid item xs={6} sm={6} md={6} lg={6} >
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleRegisterOpen}
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Box>
                      <AccountCircleIcon sx={{ fontSize: 24 }} />
                    </Box>
                    <Box>
                      <Typography sx={{ fontSize: 11 }}>{translatedata[selectedLanguage].header.register}</Typography>
                    </Box>
                  </Button>
                </Grid>
              </>
            }
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {isLoggedIn ? (
                <>
                        <ButtonGroup variant="text"  aria-label="Basic button group">
        <Button sx={{ color: "#000" }} onClick={handleTelegramClick}>
        <TelegramIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleWhatsAppClick}>
        <WhatsAppIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleEmailClick}>
        <EmailIcon />
      </Button>
</ButtonGroup>
                  <MenuItem onClick={handleClose}>
                    <Person fontSize="small" /> {translatedata[selectedLanguage].header.username} {username}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <AccountBalanceWallet fontSize="small" /> {translatedata[selectedLanguage].header.availablelimit} {balance} {translatedata[selectedLanguage].header.currency}
                  </MenuItem>
                  <MenuItem onClick={()=>opentutorial(16)}>
                    <AttachMoney fontSize="small" /> {translatedata[selectedLanguage].header.blocklimit} {blockbalance} {translatedata[selectedLanguage].header.currency}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {currentDate}
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(), navigate("/deposit") }}>
                    <AttachMoney fontSize="small" /> {translatedata[selectedLanguage].header.deposit}
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(), navigate("/withdraw") }}>
                    <AttachMoney fontSize="small" /> {translatedata[selectedLanguage].header.withdraw}
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(), navigate("/transactions") }}>
                    <Person fontSize="small" /> {translatedata[selectedLanguage].header.safe}
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(), navigate("/my-bets") }}>
                    <ReceiptIcon fontSize="small" /> {translatedata[selectedLanguage].header.coupons}
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(); navigate("/help"); }}>
                    <Badge badgeContent={notify} color="error">
                      <Support fontSize="small" />
                    </Badge>
                    &nbsp; {translatedata[selectedLanguage].header.support}
                  </MenuItem>
                  <MenuItem onClick={() => { handleClose(), navigate("/telegram") }}>
                    <TelegramIcon fontSize="small" /> {translatedata[selectedLanguage].header.telegram}
                  </MenuItem>
                  {/* <MenuItem onClick={() => { redirectforum() }}>
                    <ForumIcon fontSize="small" /> {translatedata[selectedLanguage].header.forum}
                  </MenuItem> */}
                  <MenuItem onClick={logout}>
                    <Support fontSize="small" /> {translatedata[selectedLanguage].header.logout}
                  </MenuItem>
                </>
              ) : (
                <>
                  <MenuItem onClick={handleLoginOpen}>
                    <Login fontSize="small" /> {translatedata[selectedLanguage].header.loginin}
                  </MenuItem>
                  <MenuItem onClick={handleRegisterOpen}>
                    <PersonAdd fontSize="small" /> {translatedata[selectedLanguage].header.register}
                  </MenuItem>
                </>
              )}
            </Menu>
          </Grid>

          </>
        ) : (
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {isLoggedIn ? (
              <>
                              <Box sx={{ display: 'flex', gap: 2 }}>
                  <Button color="inherit" sx={{ color: '#ece4d4' }}  onClick={()=>navigate("/transactions")} startIcon={<AccountBalanceWallet />}>Kasa</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>navigate("/deposit")} startIcon={<AttachMoney />}>Para Yatır</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>navigate("/withdraw")} startIcon={<AttachMoney />}>Para Çek</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>navigate("/my-bets")} startIcon={<Person />}>Hesabım</Button>
                  <Badge badgeContent={notify} color="error">
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={() => navigate("/help")} startIcon={<Support />}>Destek</Button>
                   </Badge>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>navigate("/Telegram")} startIcon={<TelegramIcon />}>Telegram</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={()=>logout()} startIcon={<Support />}>Çıkış Yap</Button>
                </Box>
                <Box sx={{ display: 'flex', gap: 2 }}>
                  {/* <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={() => navigate("/Poseidon")} startIcon={<SportsSoccerIcon />}>{translatedata[selectedLanguage].poseidon.Poseidon}</Button>
                  <Button color="inherit" sx={{ color: '#ece4d4' }} onClick={() => navigate("/Zeus")} startIcon={<CasinoIcon />}>{translatedata[selectedLanguage].maincard.Zeus}</Button> */}
                  <Button
                    variant="outlined"
                    size="small"
                    onClick={handleLanguageMenuOpen}
                  >
                    {selectedLanguage}
                  </Button>
                  <Menu
                    anchorEl={languageAnchorEl}
                    open={Boolean(languageAnchorEl)}
                    onClose={handleLanguageMenuClose}
                  >
                    <MenuItem onClick={() => handleLanguageChange('en')}>EN</MenuItem>
                    <MenuItem onClick={() => handleLanguageChange('tr')}>TR</MenuItem>
                    <MenuItem onClick={() => handleLanguageChange('ar')}>AR</MenuItem>
                    <MenuItem onClick={() => handleLanguageChange('de')}>DE</MenuItem>   
                     <MenuItem onClick={() => handleLanguageChange('ru')}>RU</MenuItem>
                     <MenuItem onClick={() => handleLanguageChange('es')}>ES</MenuItem>
                  </Menu>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                  <Grid container>
                    <Grid item xs={4} sm={4} md={4} lg={4}>

                      <Button
                        variant="contained"
                        size="small"
                        onClick={()=>opentutorial(16)}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          backgroundColor: "#cd7474"
                        }}
                      >
                        <Box>
                          <SafetyCheckIcon sx={{ fontSize: 24 }} />
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: 11 }}>{formatCurrency(blockbalance)}</Typography>
                        </Box>
                      </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4}>

                      <Button
                        component={Link} to="/deposit"
                        variant="contained"
                        size="small"
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                          backgroundColor: "#125f09"
                        }}
                      >
                        <Box>
                          <AccountBalanceWalletIcon sx={{ fontSize: 24 }} />
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: 11 }}>{formatCurrency(balance)}</Typography>
                        </Box>
                      </Button>
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} >
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleMenuOpen}
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'center',
                        }}
                      >
                        <Box>
                          <AccountCircleIcon sx={{ fontSize: 24 }} />
                        </Box>
                        <Box>
                          <Typography sx={{ fontSize: 11 }}>{translatedata[selectedLanguage].header.myaccount}</Typography>
                        </Box>
                      </Button>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                      >
        <ButtonGroup variant="text"  aria-label="Basic button group">
        <Button sx={{ color: "#000" }} onClick={handleTelegramClick}>
        <TelegramIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleWhatsAppClick}>
        <WhatsAppIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleEmailClick}>
        <EmailIcon />
      </Button>
</ButtonGroup>
                        <MenuItem onClick={handleClose}>
                          <Person fontSize="small" /> {translatedata[selectedLanguage].header.username} {username}
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                          <AccountBalanceWallet fontSize="small" /> {translatedata[selectedLanguage].header.availablelimit} {balance} {translatedata[selectedLanguage].header.currency}
                        </MenuItem>
                        <MenuItem onClick={()=>opentutorial(16)}>
                          <AttachMoney fontSize="small" /> {translatedata[selectedLanguage].header.blocklimit} {blockbalance} {translatedata[selectedLanguage].header.currency}
                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                          {currentDate}
                        </MenuItem>
                        <MenuItem onClick={() => { handleClose(), navigate("/deposit") }}>
                          <AttachMoney fontSize="small" /> {translatedata[selectedLanguage].header.deposit}
                        </MenuItem>
                        <MenuItem onClick={() => { handleClose(), navigate("/withdraw") }}>
                          <AttachMoney fontSize="small" /> {translatedata[selectedLanguage].header.withdraw}
                        </MenuItem>
                        <MenuItem onClick={() => { handleClose(), navigate("/transactions") }}>
                          <Person fontSize="small" /> {translatedata[selectedLanguage].header.safe}
                        </MenuItem>
                        <MenuItem onClick={() => { handleClose(), navigate("/my-bets") }}>
                          <ReceiptIcon fontSize="small" /> {translatedata[selectedLanguage].header.coupons}
                        </MenuItem>
                        <MenuItem onClick={() => { handleClose(); navigate("/help"); }}>
                          <Badge badgeContent={notify} color="error">
                            <Support fontSize="small" />
                          </Badge>
                          &nbsp; {translatedata[selectedLanguage].header.support}
                        </MenuItem>
                        <MenuItem onClick={() => { handleClose(), navigate("/telegram") }}>
                          <TelegramIcon fontSize="small" /> {translatedata[selectedLanguage].header.telegram}
                        </MenuItem>
                        {/* <MenuItem onClick={() => { redirectforum() }}>
                          <ForumIcon fontSize="small" /> {translatedata[selectedLanguage].header.forum}
                        </MenuItem> */}
                        <MenuItem onClick={logout}>
                          <Support fontSize="small" /> {translatedata[selectedLanguage].header.logout}
                        </MenuItem>


                      </Menu>
                    </Grid>
                  </Grid>
                  {/* <Typography variant="body1" sx={{ color: '#ece4d4' }}>
                    <strong style={{ color: '#ece4d4' }}>{translatedata[selectedLanguage].header.username}</strong> {username}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#4caf50' }}>
                    <strong style={{ color: '#ece4d4' }}>{translatedata[selectedLanguage].header.availablelimit}</strong> {balance} {translatedata[selectedLanguage].header.currency}
                  </Typography>
                  <Typography variant="body1" sx={{ color: '#ece4d4' }}>
                    <strong>{translatedata[selectedLanguage].header.blocklimit}</strong> {blockbalance} {translatedata[selectedLanguage].header.currency}
                  </Typography> */}
                </Box>
              </>
            ) : (
              <Box sx={{ display: 'flex', gap: 2 }}>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={handleLanguageMenuOpen}
                >
                  {selectedLanguage}
                </Button>
                <Menu
                  anchorEl={languageAnchorEl}
                  open={Boolean(languageAnchorEl)}
                  onClose={handleLanguageMenuClose}
                >
                  <MenuItem onClick={() => handleLanguageChange('en')}>EN</MenuItem>
                  <MenuItem onClick={() => handleLanguageChange('tr')}>TR</MenuItem>
                  <MenuItem onClick={() => handleLanguageChange('ar')}>AR</MenuItem>
                  <MenuItem onClick={() => handleLanguageChange('de')}>DE</MenuItem>    
                  <MenuItem onClick={() => handleLanguageChange('ru')}>RU</MenuItem>
                  <MenuItem onClick={() => handleLanguageChange('es')}>ES</MenuItem>
                </Menu>
                <Button color="inherit" sx={{ color: '#d79f09' }} startIcon={<Login />} onClick={handleLoginOpen}>{translatedata[selectedLanguage]?.header.loginin}</Button>
                <Button color="inherit" sx={{ color: '#d79f09' }} startIcon={<PersonAdd />} onClick={handleRegisterOpen}>{translatedata[selectedLanguage].header.register}</Button>
              </Box>
            )}
          </Box>
        )}
      </Toolbar>
      <LoginDialog setBalance={setBalance} setNotify={setNotify} open={isLoginOpen} setUsername={setUsername} selectedLanguage={selectedLanguage} setIsLoggedIn={setIsLoggedIn} onClose={handleLoginClose} />
      <RegisterDialog open={isRegisterOpen} onClose={handleRegisterClose} promocodes={promocodes} selectedLanguage={selectedLanguage} />
    </AppBar>
  );
};

export default Header;
