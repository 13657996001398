import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import {
    AppBar, Toolbar, IconButton, Typography,Button, Container, Box, Grid, Table, TableBody, TableCell,Snackbar,Alert, TableContainer, TableHead, TableRow, Paper, Collapse, Icon,useMediaQuery,useTheme,ToggleButtonGroup,ToggleButton
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import translatedata from '../translate.json';
import "dayjs/locale/tr";
const WithdrawReq = ({selectedLanguage}) => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const [couponData, setCouponData] = useState([]);
    const [dateFilter, setDateFilter] = useState([]);
    const [dataf, setDataf] = useState(null);
    const [hoveredRow, setHoveredRow] = useState(null);
    const [statuscoup,setStatuscoup]= useState("0");
    const [totalBet, setTotalBet] = useState(0);
    const [totalWin, setTotalWin] = useState(0);
    const [totalDeposit, setTotalDeposit] = useState(0);
    const [totalWithdraw, setTotalWithdraw] = useState(0);
    const [last, setLast] = useState(0);
    const [message, setMessage] = useState('');
    const [stat, setStat] = useState('info'); // info, success, error
    const [openSnack, setOpenSnack] = useState(false);
    const [profit, setTotalProfit] = useState(0);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setOpenSnack(false);
      };
    const handleChangedate = (date) => {
        if (date[0] && date[1]) {
            const localDate0 = new Date(date[0].toLocaleString());
            const localDate1 = new Date(date[1].toLocaleString());
            localDate0.setDate(localDate0.getDate() + 1);
            localDate1.setDate(localDate1.getDate() + 1);
            setDateFilter([localDate0, localDate1]);
        }
    };
    const cancelWithdraw=(id)=>{
        const token = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };
        axios.post("https://api.youbetshop.com/api/payment/verify-withdraw-payment", { reqid: id,confirm:2 },config)
          .then(response => {
            if(response.data.success==true){
            setMessage(translatedata[selectedLanguage].withdrawapprove.cancelwith);
            setStat('success');
            setOpenSnack(true);
            recheck();
        }else{
            setMessage(response.data.message);
            setStat('error');
            setOpenSnack(true);

        }
          })
          .catch(error => {
            console.error('Hata', error);
            setMessage(translatedata[selectedLanguage].withdrawapprove.error);
            setStat('error');
            setOpenSnack(true);
          });
    }
    const dateFormatted = (tarih) => {
        const dateObj = new Date(tarih);
        const day = dateObj.getDate().toString().padStart(2, '0');
        const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
        const year = dateObj.getFullYear().toString();

        return `${year}-${month}-${day}`;
    };
    const formdate =(date)=>{
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');
        const milliseconds = String(date.getMilliseconds()).padStart(3, '0');
        const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
        return formattedDate;
    }

    const recheck=()=>{
        let params;
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        let date1 = dateFilter[0];
        let date2 = dateFilter[1];

        if (!date1 && !date2) {
        // Mevcut tarih ve saat
        const now = new Date();
    
        // Ayın ilk günü saat 00:00:00
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        firstDayOfMonth.setHours(0, 0, 0, 0);
    
        // Bugünün son saati olan 23:59:59
        const endOfToday = new Date(now);
        endOfToday.setHours(23, 59, 59, 999);
    

            date1 = formdate(firstDayOfMonth);
            date2 = formdate(endOfToday);
        } else {
            date1 = dateFormatted(date1) + 'T00:00:00.000Z';
            date2 = dateFormatted(date2) + 'T23:59:59.999Z';
        }

        if (date1 && date2) {
            params = { date1: String(date1), date2: String(date2) };
        }

        axios
            .post("https://api.youbetshop.com/api/requestwithdrawreportuser", params, config)
            .then(function (response) {
                setDataf(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }
    useEffect(() => {
        let params;
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        let date1 = dateFilter[0];
        let date2 = dateFilter[1];

        if (!date1 && !date2) {
        // Mevcut tarih ve saat
        const now = new Date();
    
        // Ayın ilk günü saat 00:00:00
        const firstDayOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
        firstDayOfMonth.setHours(0, 0, 0, 0);
    
        // Bugünün son saati olan 23:59:59
        const endOfToday = new Date(now);
        endOfToday.setHours(23, 59, 59, 999);
    

            date1 = formdate(firstDayOfMonth);
            date2 = formdate(endOfToday);
        } else {
            date1 = dateFormatted(date1) + 'T00:00:00.000Z';
            date2 = dateFormatted(date2) + 'T23:59:59.999Z';
        }

        if (date1 && date2) {
            params = { date1: String(date1), date2: String(date2) };
        }

        axios
            .post("https://api.youbetshop.com/api/requestwithdrawreportuser", params, config)
            .then(function (response) {
                setDataf(response.data.data);
            })
            .catch(function (error) {
                console.log(error);
            });
    }, [dateFilter]);
    
    useEffect(() => {
        if(statuscoup=="1"){
            const data=dataf.filter((item)=>item.type_id==2 || item.type_id==3 || item.type_id==5 || item.type_id==6 || item.type_id==4)
            setCouponData(data)
        }else if(statuscoup=="2"){
            const data=dataf.filter((item)=>item.type_id==1 || item.type_id==10 ||item.type_id==14 || item.type_id==8 || item.type_id==9 )
            setCouponData(data)
        }else{
            setCouponData(dataf)
        }
        
    }, [statuscoup,dataf])
    
    useEffect(() => {
        const totalbet = dataf
      ?.filter(obj => obj.type_id === 3 || obj.type_id === 7)
      .reduce((sum, obj) => sum + Number(obj.amount), 0);
         setTotalBet(totalbet?.toFixed(2));

         const totalwin = dataf
         ?.filter(obj => obj.type_id === 2 || obj.type_id === 4 || obj.type_id ===6)
         .reduce((sum, obj) => sum + Number(obj.amount), 0);
         setTotalWin(totalwin?.toFixed(2));
         setTotalProfit((totalwin-totalbet)?.toFixed(2))

         const totaldeposit = dataf
         ?.filter(obj => obj.type_id === 1 )
         .reduce((sum, obj) => sum + Number(obj.amount), 0);
         setTotalWin(totalwin?.toFixed(2));
         setTotalProfit((totalwin-totalbet)?.toFixed(2))
         setTotalDeposit(totaldeposit?.toFixed(2))
         const totalwithdraw = dataf
         ?.filter(obj => obj.type_id === 10)
         .reduce((sum, obj) => sum + Number(obj.amount), 0);
         setTotalWin(totalwin?.toFixed(2));
         setTotalProfit((totalwin-totalbet)?.toFixed(2))
         setTotalWithdraw(totalwithdraw?.toFixed(2))
         setLast((totaldeposit-totalwithdraw)?.toFixed(2))
    }, [dataf])
    

    const getStatusbg = (typeId) => {
        if (typeId === 0 ) {
          return "transparent";
        } else if(typeId === 1 ) {
          return "rgb(41 139 9)";
        }else{
            return "rgb(205 1 1)";
        }
      };
      const getStatus = (typeId) => {
        if (typeId === 0 ) {
          return translatedata[selectedLanguage].withdrawreq.waiting;
        } else if(typeId === 1 ) {
          return translatedata[selectedLanguage].withdrawreq.complated;
        }else{
            return translatedata[selectedLanguage].withdrawreq.canceled;
        }
      };
    const handleChanges = (e) => {
        setStatuscoup(e.target.value)
    };
    const formatDateTime = (dateString) => {
        const dateObj = new Date(dateString);

        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');

        const hours = String(dateObj.getHours()).padStart(2, '0');
        const minutes = String(dateObj.getMinutes()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}`;
    };
    function formatDate(isoString) {
        // Tarih nesnesi oluştur ve UTC saat dilimine göre bilgileri al
        const date = new Date(isoString);
        
        const year = date.getUTCFullYear();
        const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Aylar 0-11 arası olduğu için +1 ekliyoruz
        const day = String(date.getUTCDate()).padStart(2, '0');
        const hours = String(date.getUTCHours()).padStart(2, '0');
        const minutes = String(date.getUTCMinutes()).padStart(2, '0');
        const seconds = String(date.getUTCSeconds()).padStart(2, '0');
        
        // İstenen biçimde birleştir
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }
    return (
        <>

                <Box  sx={{ backgroundColor: "#ededed", borderRadius: "1rem", padding: "15px" }}>
                    <Grid container>
                        <Grid item xs={12} sx={{  paddingTop: "20px" }}>
                            <Typography variant="h4" sx={{ color: "#333" }}>
                            {translatedata[selectedLanguage].withdraw.withdrawreq}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} sm={3} lg={3} >
                            <LocalizationProvider dateAdapter={AdapterDayjs} sx={{ m: 1 }}  adapterLocale="tr">
                                <DateRangePicker
                                    sx={{ m: 1, minWidth: '15%' }}
                                    label={dateFilter[0] && dateFilter[1] ? `${dateFormatted(dateFilter[0])} - ${dateFormatted(dateFilter[1])}` :  translatedata[selectedLanguage].withdrawreq.datefilter}
                                    slots={{ field: SingleInputDateRangeField }}
                                    onChange={handleChangedate}
                                />
                            </LocalizationProvider>
                        </Grid>
                        {statuscoup==1?
                        <Grid item xs={12} md={12} sm={12} lg={12} sx={{marginLeft:"5px"}}>
                            <Grid container justifyContent={"center"}  alignItems={"center"}>
                            <Grid item xs={3.7} md={3.7} sm={3.7} lg={3.7} m={0.5} sx={{padding:"5px",backgroundColor:"#faa8a8",borderRadius:"5px"}}>
                                <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].withdrawreq.totalbet}:</span><br/>{totalBet}</Typography>
                            </Grid>
                            <Grid item xs={3.7} md={3.7} sm={3.7} lg={3.7} m={0.5} sx={{padding:"5px",backgroundColor:"#c1fcd6",borderRadius:"5px"}}>
                            <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].withdrawreq.totalwin}:</span><br/>{totalWin}</Typography>
                            </Grid>
                            <Grid item xs={3.7} md={3.7} sm={3.7} lg={3.7} m={0.5} sx={{padding:"5px",backgroundColor:profit<0?"#faa8a8":"#c1fcd6",borderRadius:"5px"}}>
                            <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].withdrawreq.profit}:</span><br/>{profit}</Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        :
                        statuscoup==2?
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                            <Grid container justifyContent={"center"}  alignItems={"center"}>
                            <Grid item xs={3.7} md={3.7} sm={3.7} lg={3.7} m={0.5} sx={{padding:"5px",backgroundColor:"#faa8a8",borderRadius:"5px"}}>
                                <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].withdrawreq.totaldeposit}:</span><br/>{totalDeposit}</Typography>
                            </Grid>
                            <Grid item xs={3.7} md={3.7} sm={3.7} lg={3.7} m={0.5} sx={{padding:"5px",backgroundColor:"#c1fcd6",borderRadius:"5px"}}>
                            <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].withdrawreq.totalwithdraw}:</span><br/>{totalWithdraw}</Typography>
                            </Grid>
                            <Grid item xs={3.7} md={3.7} sm={3.7} lg={3.7} m={0.5} sx={{padding:"5px",backgroundColor:last<0?"#faa8a8":"#c1fcd6",borderRadius:"5px"}}>
                            <Typography sx={{fontSize:isMobile?"0.8rem":"1rem"}}><span style={{fontWeight:"600"}}>{translatedata[selectedLanguage].withdrawreq.response}:</span><br/>{last}</Typography>
                            </Grid>
                            </Grid>
                        </Grid>
                        :""
                        }
                        <Grid item xs={12}>
                            <TableRow />
                            <TableContainer component={Paper} sx={{ backgroundColor: "#ededed" }}>
                                <Table>
                                    <TableHead sx={{ backgroundColor: "#ece4d4" }}>
                                        <TableRow>

                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].withdrawreq.ID}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].withdrawreq.Method}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].withdrawreq.amount}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].withdrawreq.status}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].withdrawreq.date}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{translatedata[selectedLanguage].withdrawreq.account}</TableCell>
                                            <TableCell sx={{ color: "#d79f09",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {couponData?.map((coupon, index) => (
                                            <>
                                                <TableRow key={coupon.coupon_id} onMouseEnter={() => setHoveredRow(index)}
                                                    onMouseLeave={() => setHoveredRow(null)}
                                                    sx={{
                                                        borderLeft:"solid 3px",
                                                        borderColor:coupon.status==0?"#000":getStatusbg(coupon.status),
                                                        backgroundColor: hoveredRow === index ? '#f0f0f0' : (index % 2 === 0 ? '#ededed' : '#ece4d4'),
                                                        height: hoveredRow === index ? '60px' : '50px', // Satırın boyutunu burada ayarlayabilirsiniz
                                                        transition: 'height 0.3s ease', // Boyut değişimini düzgünleştirmek için geçiş efekti ekleyin
                                                    }}>
                                                    <TableCell sx={{ color:coupon.status==0?"#000":getStatusbg(coupon.status) }}>
                                                       {coupon.id}
                                                    </TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.method}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.amount}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem",backgroundColor:getStatusbg(coupon.status) }}>{coupon.permission_status==3?translatedata[selectedLanguage].withdrawreq.emailconfirm:getStatus(coupon.status)}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem",minWidth:"70px" }}>{formatDate(coupon.createdAt)}</TableCell>
                                                    <TableCell sx={{ color: coupon.is_win == 1 ? "rgb(39 139 0)" : coupon.is_win == 2 ? "rgb(203 0 0)" : "rgba(0, 0, 0, 0.87)",padding:"6px",fontSize:isMobile&&"0.65rem" }}>{coupon.note}</TableCell>
                                                    {coupon.permission_status==3?
                                                   <TableCell><Button variant={"outlined"} onClick={()=>cancelWithdraw(coupon.id)}>İptal</Button></TableCell> 
                                                   :         <TableCell></TableCell> 
                                                        }
                                                </TableRow>
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>
                </Box>
                <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <Alert
            onClose={handleClose}
            severity={stat}
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        </Snackbar>
        </>
    );
}

export default WithdrawReq;
