import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminLogin = () => {
  const [cookies, setCookie, removeCookie] = useCookies(['serviceToken']);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  // Function to handle automatic login with token
  const handleAutoLogin = (token) => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, // Attach the token to the headers
      },
    };

    axios.post("https://api.youbetshop.com/verify", { token }, config)
      .then(response => {
        if (response.data.success) {
          // Store token in cookies
          setCookie('serviceToken', token, { path: '/' });

          // Set user login state
          setIsLoggedIn(true);

          // Redirect to the admin dashboard or any page after successful login
          navigate('/');
        } else {
          console.error('Invalid token');
        }
      })
      .catch(error => {
        console.error('Error verifying token:', error);
      });
  };

  useEffect(() => {
    // Extract the token from the URL only on the adminlogin page
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');

    if (token) {
      handleAutoLogin(token); // Trigger automatic login if token is present
    }
  }, []); // Only run on the first render

  return (
    <div>
      {isLoggedIn ? (
        <h2>Logging in...</h2>
      ) : (
        <h2>Admin Login</h2>
      )}
      {/* Add other admin login fields or UI here if needed */}
    </div>
  );
};

export default AdminLogin;
