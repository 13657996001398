import React, { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogTitle,TextField, DialogContent, DialogActions,Box,Link ,ButtonGroup, Card, CardContent,InputAdornment, Typography, Grid, Avatar,Container,Button,CircularProgress,Snackbar,Alert,AppBar,Toolbar,IconButton,useMediaQuery,useTheme   } from '@mui/material';
import ares from '../img/ares.png';
import zeus from '../img/zeus.png';
import poseidon from '../img/poseidon.png';
import Cookies from 'js-cookie';
import Tutorial from '../component/Tutorial';
import translatedata from '../translate.json';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import TelegramIcon from '@mui/icons-material/Telegram';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import { Visibility, VisibilityOff } from '@mui/icons-material';

const Home = ({setLoginOpen,isLoggedIn,aresfee,poseidonfee,zeusfee,selectedLanguage,setChatOpen,messages,mailverify, onClose, setIsLoggedIn, setUsername, setBalance,setNotify}) => {
  const [tutorialid,setTutorialid]=useState(0);
  const [tutorialopen,setTutorialOpen]=useState(false);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [message, setMessage] = useState('');
  const [stat, setStat] = useState('error');
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };
  if(isLoggedIn){
    navigate("/Poseidon");
  }
  const handleLogin = () => {
    axios.post("https://api.youbetshop.com/login", { email: email, pass: password,user_type:1 })
      .then(response => {
        if (response.data.success) {
          setMessage(translatedata[selectedLanguage].logindilaog.loginsuccess);
          setIsLoggedIn(true);
          setOpen(true);
          setCookie('serviceToken', response.data.data.token);
          setCookie('email', response.data.data.email);
          setStat("success");
          navigate("/Poseidon");
          onClose();
        } else {
          setMessage(response.data.data.message);
          setOpen(true);
          setStat("error");
        }
     
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setMessage("Hata,Lütfen Tekrar Deneyin");
        setOpen(true);
        setStat("error");
        console.log("burda")
      });
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleLogin();
      
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen(true);
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  const handleForgotPasswordSubmit = () => {
    setLoading(true);
    axios.post("https://api.youbetshop.com/forgotpass", { email: forgotEmail })
      .then(response => {
        if (response.data.success) {
          setMessage(translatedata[selectedLanguage].logindilaog.passmailsuccess);
          setStat("success");
        } else {
          setMessage(translatedata[selectedLanguage].logindilaog.passmailerror);
          setStat("error");
        }
        setOpen(true);
        setForgotPasswordOpen(false);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error sending forgot password email:', error);
        setMessage(translatedata[selectedLanguage].logindilaog.passmailerror);
        setOpen(true);
        setStat("error");
        setForgotPasswordOpen(false);
        setLoading(false);
      });
  };
  const handleTelegramClick = () => {
    window.open("https://t.me/youbetshop", "_blank");
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/447437586218", "_blank");
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:info@youbetshop.com";
  };
  const characters = [

    {
      name: "Poseidon",
      description: translatedata[selectedLanguage].maincard.poseidonpart1+(poseidonfee>0?poseidonfee+translatedata[selectedLanguage].maincard.poseidonpart2:40+translatedata[selectedLanguage].maincard.poseidonpart2),
      discount: '%40',
      img: poseidon
    },
    {
      name: "Zeus",
      description:translatedata[selectedLanguage].maincard.zeusdesc,
      discount: '%20',
      img: zeus
    }
  ];
  const sendmail = () => {
    setLoading(true);
    const accessToken = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    axios.post("https://api.youbetshop.com/api/user/verifymail", "", config)
      .then(response => {
        if (response.data.success=true) {
          setLoading(false);
          setMessage("Mail Gönderildi.");
          setOpen(true);
          setStat("success");
        } else {
          setLoading(false);
          setMessage(response.data.message);
          setOpen(true);
          setStat("error");
        }
      })
      .catch(error => {
        console.error('Error creating coupon:', error);
        setMessage(error);
        setOpen(true);
        setStat("error");
      });
  }
  useEffect(() => {
    if(isLoggedIn){
      const loginfirst=Cookies.get('loginfirst');
      const tutorial=Cookies.get('tutorial');
      console.log(loginfirst)
      if(loginfirst){
        if(!tutorial=="2" && !loginfirst=="2" ){
     
          setTutorialid(17)
        }
      }else{
       
        setTutorialid(17)
      }
    }
    }, [isLoggedIn])
  return (
    <>      {isLoggedIn&&
      <AppBar position="static">
        <Toolbar>
          <Box sx={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
          <IconButton edge="start" color="inherit" aria-label="back" >
            <Typography variant="h6">
              {translatedata[selectedLanguage].starrace.home}
            </Typography>
          </IconButton>
          </Box>
        </Toolbar>
      </AppBar>}
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center',marginTop:"15px",borderRadius:"20px" }}>
            <Grid container sx={{ maxHeight: "150rem",display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: "50rem", backgroundColor: "#ece4d4", borderRadius: "1rem",borderRadius:"20px" }}>
            <Box sx={{width:"100%",backgroundColor:"#d79f09"}}>  <DialogTitle> {translatedata[selectedLanguage].logindilaog.loginin}   </DialogTitle></Box>

            <Box sx={{padding:"10px"}}>
          <TextField
            autoFocus
            margin="dense"
            label={translatedata[selectedLanguage].logindilaog.email}
            type="email"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={handleKeyPress}
          />
      
          <TextField
            margin="dense"
            label={translatedata[selectedLanguage].logindilaog.pass}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyDown={handleKeyPress}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
          <Link
            component="button"
            variant="body2"
            onClick={handleForgotPasswordOpen}
            style={{ display: 'block', marginTop: 8 }}
          >
           {translatedata[selectedLanguage].logindilaog.passresset}
          </Link>


        <Box sx={{display:"flex",justifyContent:"space-between",width:"100%"}}>
        <Box >

</Box>
<Box sx={{padding:"20px"}}>
          <Button onClick={onClose} color="primary" variant={"outlined"} sx={{marginRight:"2px"}}> {translatedata[selectedLanguage].logindilaog.cancel}</Button>
          <Button onClick={handleLogin} color="primary" variant={"contained"}> {translatedata[selectedLanguage].logindilaog.loginin}</Button>
          </Box>
          </Box>
          </Box>
        <Box sx={{width:"100%",backgroundColor:"#d79f09",padding:"5px"}}>
        <ButtonGroup variant="text"  aria-label="Basic button group">
        <Button sx={{ color: "#000" }} onClick={handleTelegramClick}>
        <TelegramIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleWhatsAppClick}>
        <WhatsAppIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleEmailClick}>
        <EmailIcon />
      </Button>
</ButtonGroup>
</Box>


      <Dialog open={forgotPasswordOpen} onClose={handleForgotPasswordClose}>
        <DialogTitle> {translatedata[selectedLanguage].logindilaog.passresset}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="E-posta"
            type="email"
            fullWidth
            value={forgotEmail}
            onChange={(e) => setForgotEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleForgotPasswordClose} color="primary">{translatedata[selectedLanguage].logindilaog.cancel}</Button>
          <Button onClick={handleForgotPasswordSubmit} color="primary" disabled={loading}>
            {loading ? <CircularProgress size={24} /> : translatedata[selectedLanguage].logindilaog.send}
          </Button>
        </DialogActions>
      </Dialog>

</Grid>
      </Box>
         <Tutorial tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid}/>
         <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={stat}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {message}

        </Alert>
      </Snackbar>
         </>
  );
};

export default Home;
