import React, { useState, useEffect, useRef } from 'react';
import { IconButton, Badge, Box, Typography, TextField, Button, Divider, ButtonGroup } from '@mui/material';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import CloseIcon from '@mui/icons-material/Close';
import { format } from 'date-fns';
import io from 'socket.io-client';
import notificationSound from './notification.mp3'; // Ses dosyasını buraya ekleyin
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import EmailIcon from '@mui/icons-material/Email';
import TelegramIcon from '@mui/icons-material/Telegram';
import { useCookies } from 'react-cookie';
const SOCKET_SERVER_URL = 'https://helpchat.youbetshop.com';

const ChatComponent2 = () => {
  const [token,setToken]= useState(false);
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const [newMessage, setNewMessage] = useState('');
  const [socket, setSocket] = useState(null);
  const [unreadCount, setUnreadCount] = useState(0);
  const messagesEndRef = useRef(null);
  const audioRef = useRef(null); // Ses dosyası referansı
  const openRef = useRef(open); // open değerini tutmak için useRef kullanıyoruz

  useEffect(() => {
    const token2 = cookies.serviceToken;
    setToken(token2)
  }, []);
  useEffect(() => {
    openRef.current = open;
  }, [open]);

  const handleTelegramClick = () => {
    window.open("https://t.me/youbetshop", "_blank");
  };

  const handleWhatsAppClick = () => {
    window.open("https://wa.me/447437586218", "_blank");
  };

  const handleEmailClick = () => {
    window.location.href = "mailto:info@youbetshop.com";
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    const newSocket = io(SOCKET_SERVER_URL, {
      auth: {
        token: token,
        user_type: 1,
      },
    });

    setSocket(newSocket);

    // Eski mesajları yüklendiğinde
    newSocket.on('previousMessages', (loadedMessages) => {
      const messagesWithTimestamp = loadedMessages.map((msg) => ({
        ...msg,
        timestamp: msg.timestamp ? new Date(msg.timestamp) : new Date(),
      }));
      setMessages(messagesWithTimestamp);
    });

    // Yeni mesaj geldiğinde state'i güncelle
    newSocket.on('message', (message) => {
      if (message && message.text) {
        setMessages((prevMessages) => [
          ...prevMessages,
          { ...message, timestamp: new Date() },
        ]);

        // Ref kullanarak güncel open değerini kontrol et
        if (!openRef.current) {
          setUnreadCount((prevCount) => prevCount + 1);
          playNotificationSound(); // Ses çal
        }
      }
    });

    newSocket.on('connect_error', (err) => {
      console.error('Bağlantı hatası:', err.message);
    });

    return () => {
      newSocket.disconnect();
    };
  }, [token]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const toggleChatWindow = () => {
    setOpen(!open);
    if (!open) {
      setUnreadCount(0);
    }
    setTimeout(() => {
      scrollToBottom(); 
    }, 100);
  };

  const handleSendMessage = () => {
    if (newMessage.trim() && socket) {
      socket.emit('message', newMessage);
      setNewMessage('');
    }
  };

  const playNotificationSound = () => {
    const audio = audioRef.current;
    if (audio && audio.play) {
      audio.play()
        .then(() => {
          console.log('Ses başarıyla çalındı');
        })
        .catch((error) => {
          console.error('Ses çalma hatası:', error);
        });
    } else {
      console.error('Ses çalınamadı');
    }
  };

  const groupMessagesByDate = (messages) => {
    return messages.reduce((groups, message) => {
      const date = format(new Date(message.timestamp), 'dd MMM yyyy');
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(message);
      return groups;
    }, {});
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && newMessage.trim()) {
      handleSendMessage();
    }
  };

  const groupedMessages = groupMessagesByDate(messages);

  return (
   <>
    
        <Box
          sx={{
 minHeight:"550px",
            width: "100%",
            backgroundColor: '#fff',
            boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: 8,
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            zIndex: 5,
          }}
        >
          <Box sx={{ backgroundColor: '#d79f09', color: '#fff', p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" sx={{fontSize:"0.99rem"}}>Live Help - Canlı Destek </Typography>
            <ButtonGroup variant="text"  aria-label="Basic button group">
        <Button sx={{ color: "#000" }} onClick={handleTelegramClick}>
        <TelegramIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleWhatsAppClick}>
        <WhatsAppIcon />
      </Button>
      <Button sx={{ color: "#000" }} onClick={handleEmailClick}>
        <EmailIcon />
      </Button>
</ButtonGroup>
          </Box>

          <Box sx={{ flex: 1, p: 2, overflowY: 'auto' }}>
            {Object.keys(groupedMessages).map((date) => (
              <Box key={date} sx={{ mb: 2 }}>
                <Typography variant="subtitle2" sx={{ color: '#1976d2', mb: 1 }}>
                  {date}
                </Typography>
                {groupedMessages[date].map((message, index) => (
                  <Box
                    key={index}
                    sx={{
                      backgroundColor: message.user_type === 1 ? '#e8ffee' : '#f0f0f0',
                      borderRadius: 2,
                      p: 1,
                      mb: 1,
                      maxWidth: '80%',
                      ml: message.user_type === 1 ? 'auto' : 0,
                      mr: message.user_type === 1 ? 0 : 'auto',
                      textAlign: message.user_type === 1 ? 'right' : 'left',
                    }}
                  >
                    <Typography variant="caption" sx={{ display: 'block', mt: 0.5, color: '#bbb', fontSize: '10px' }}>
                      {message.sender} {format(new Date(message.timestamp), 'HH:mm')}
                    </Typography>
                    <Typography variant="body2">{message.text}</Typography>
                  </Box>
                ))}
              </Box>
            ))}
            <div ref={messagesEndRef} />
          </Box>

          <Divider />

          <Box sx={{ p: 2, display: 'flex', alignItems: 'center' }}>
            <TextField
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={handleKeyDown}
              fullWidth
              variant="outlined"
              size="small"
              placeholder="Type Message"
            />
            <Button variant="contained" color="primary" onClick={handleSendMessage} sx={{ ml: 1 }}>
              Send
            </Button>

          </Box>

        </Box>
      <audio ref={audioRef} src={notificationSound} preload="auto" />
    </>
  );
};

export default ChatComponent2;
