import React, { useState, useEffect,useRef } from 'react';
import {
    Container, Box, Typography, TextField, Button, Grid, Paper, List, ListItem, ListItemText,
    useMediaQuery, useTheme, Modal,Snackbar,Alert, AppBar, Toolbar,IconButton
} from '@mui/material';
import axios from 'axios';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import translatedata from '../translate.json';
import Qusetion from '../media/question.gif';
import Tutorial from '../component/Tutorial';
import Whatsapp from '../media/whatsapp.png';
import Telegram from '../media/Telegram.png';
import { GroupWork } from '@mui/icons-material';
import ChatComponent2 from '../component/chatcomp2';
function App({selectedLanguage}) {
    const [tickets, setTickets] = useState([]);
    const [ticketid, setTicketid] = useState();
    const [openModal, setOpenModal] = useState(false);
    const [newTicketTitle, setNewTicketTitle] = useState('');
    const [newTicketMessage, setNewTicketMessage] = useState('');
    const [tickettitle, setTicketTitle] = useState('');
    const [ticketmessage, setTicketMessage] = useState([]);
    const theme = useTheme();
    const [cookies, setCookie] = useCookies(['serviceToken']);
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open,setOpen]=useState(false);
    const [message,setMessage]=useState(false);
    const [stat,setStat]=useState(false);
    const messagesBoxRef = useRef(null);
    const [userticketMessage, setUserTicketMessage] = useState('');
    const [tutorialid, setTutorialid] = useState(7);
    const [tutorialopen, setTutorialOpen] = useState(false);
    const navigate = useNavigate();
    
    useEffect(() => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        if (cookies.serviceToken) {
            axios
                .get("https://api.youbetshop.com/api/ticket/get-ticket-user", config)
                .then(function (response) {
                    setTickets(response.data.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }, [cookies.serviceToken]);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);
    const recheck = ()=>{
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        if (cookies.serviceToken) {
            axios
                .get("https://api.youbetshop.com/api/ticket/get-ticket-user", config)
                .then(function (response) {
                    setTickets(response.data.data);
                    if(ticketid){
                        const tic=response.data.data.find((item)=>item.id==ticketid)
                        setTicketTitle(tic?.title);
                        setTicketMessage(tic?.messages)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    }
    const handleCreateTicket = () => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        axios
        .post("https://api.youbetshop.com/api/ticket/create-ticket", {"title":newTicketTitle,"message":newTicketMessage}, config)
        .then(function (response) {
            if(response.data.success){
                setOpen(true)
                setStat("success")
                setMessage(translatedata[selectedLanguage].notify.successticket)
                recheck();
                handleClose();
            }else{
                setOpen(true)
                setStat("error")
                setMessage(translatedata[selectedLanguage].notify.errorticket)
                handleClose();
            }
            
        })
        .catch(function (error) {
            setOpen(true)
            setStat("error")
            setMessage(error)
            handleClose();
            console.log(error);
        });

      
    };
    const handleCloseSnack = (event: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };
      const getStatus =(id)=>{
        if(id==0){
            return translatedata[selectedLanguage].help.ticketcreate;
        }else if(id==1){
            return translatedata[selectedLanguage].help.adminres;
        }else if(id==2){
            return translatedata[selectedLanguage].help.reswait;
        }else{
            return translatedata[selectedLanguage].help.closed;
        }
      }
      const openTicket=(id)=>{
        setTicketid(id);
      }
      useEffect(() => {
       const tic=tickets.find((item)=>item.id==ticketid)
       setTicketTitle(tic?.title);
       setTicketMessage(tic?.messages)
       recheck();
       setTimeout(() => {
       if (messagesBoxRef.current) {
        const { scrollHeight, clientHeight } = messagesBoxRef.current;
        messagesBoxRef.current.scrollTop = scrollHeight - clientHeight+1000;
    } 
}, 500);
      }, [ticketid])
      
      const handleSendMessage = () => {
        const accessToken = cookies.serviceToken;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
        };
        axios
        .post("https://api.youbetshop.com/api/ticket/res-ticket-user", {"ticket_id":ticketid,"message":userticketMessage}, config)
        .then(function (response) {
            if(response.data.success){

                recheck();
                handleClose();
                setUserTicketMessage('');
                setTimeout(() => {
                    if (messagesBoxRef.current) {
                        const { scrollHeight, clientHeight } = messagesBoxRef.current;
                        messagesBoxRef.current.scrollTop = scrollHeight - clientHeight+1000;
                    } 
                }, 500);

            }else{
                setOpen(true)
                setStat("error")
                setMessage(translatedata[selectedLanguage].notify.errormessage)
                handleClose();
            }
            
        })
        .catch(function (error) {
            setOpen(true)
            setStat("error")
            setMessage(error)
            handleClose();
            console.log(error);
        });
     
    };
    const opentutorial = (id) => {
    
        setTutorialid(id);
      }
      const goWp = () => {
        const phoneNumber = '+447437586218';
        const url = `https://wa.me/${phoneNumber}`;
    
        window.open(url, '_blank');
      };
      const goTg = () => {
        const url = `https://t.me/youbetshop`;
    
        window.open(url, '_blank');
      };
    return (
        <>
    <AppBar position="static">
        <Toolbar>
          <Box sx={{display:"flex",justifyContent:"space-between",width:"100%",alignItems:"center"}}>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            <Typography variant="h6">
            {translatedata[selectedLanguage].help.home}
            </Typography>
          </IconButton>
          <Typography variant="body1" style={{ marginRight: '10px' }}>
          {translatedata[selectedLanguage].help.support}
          </Typography>
          </Box>
        </Toolbar>
      </AppBar>
        <Container sx={{ my: 4 }}>
                            <Box sx={{justifyContent:"space-between",alignItems:"center",backgroundColor:"#2d2d2db8",marginBottom:"5px",display:"flex"}}>
                            <Typography variant="h4" sx={{color: "#d79f09" }}>
                            {translatedata[selectedLanguage].help.support}
                            </Typography>
                            {/* <img src={Qusetion} style={{ maxWidth: "75px", display: "flex" }} onClick={() => opentutorial(7)} /> */}
                            </Box>
            <Box >
                <Grid container spacing={2}>
                   
                        <Grid item xs={12} md={4}>
                            <ChatComponent2/>
                        </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed"}}>
                            <Typography variant="h6" gutterBottom>
                            {"Canlı Destek"}
                                
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary={"Canlı destek ekibimize tüm sorularınızı sorabilir ve destek taleplerinizi bildirebilirsiniz ortalama cevap süresi 15 dakikadır , lütfen destek bildirimlerinizi takip ediniz. Canlı desteğin yoğun olduğu saatlerde daha acil bir destek almanız gerektiğini düşünüyorsanız veya ekran resmi , video gibi ek bildirimlerde bulunmanız gerekiyorsa whatsapp, telegram ve mail kanallarından da iletişime geçebilirsiniz."} />
                                </ListItem>
                              
                            </List>
                        </Paper>
             
                                <Paper sx={{ p: 2,marginTop:"15px",backgroundColor:"#ededed" }}>
                                    <Box sx={{width:"100%",display:"flex",alignItems:"center",backgroundColor:"#25D366",borderRadius:"7px",padding:"5px"}} onClick={()=>goWp()}>
                                        <img src={Whatsapp} style={{maxWidth:"50px",marginRight:"5px"}} />
                                        <Typography variant='h5'>Whatsapp</Typography>
                                    </Box>
                                    <Box sx={{width:"100%",display:"flex",alignItems:"center",backgroundColor:"#239cd7",borderRadius:"7px",padding:"5px",mt:2}} onClick={()=>goTg()}>
                                        <img src={Telegram} style={{maxWidth:"50px",marginRight:"5px"}} />
                                        <Typography variant='h5'>Telegram</Typography>
                                    </Box>
                                </Paper>
                            
                        {/* <Paper sx={{ mt: 2, p: 2,backgroundColor:"#ededed" }}>
                            <Typography variant="h6" gutterBottom>
                            {translatedata[selectedLanguage].help.quest21}
                            </Typography>
                            <Typography paragraph>
                            {translatedata[selectedLanguage].help.quest22}
                            </Typography>
                            <List>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res5} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res6} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res7} />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary={translatedata[selectedLanguage].help.res8} />
                                </ListItem>
                            </List>
                        </Paper> */}

                    </Grid>

                </Grid>
            </Box>
            <Modal open={openModal} onClose={handleClose}>
                <Paper sx={{ p: 4, width: isMobile ? '90%' : '40%', margin: 'auto', mt: isMobile ? 4 : 10 }}>
                    <Typography variant="h6" gutterBottom>
                    {translatedata[selectedLanguage].help.newticket}
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label={translatedata[selectedLanguage].help.title}
                        value={newTicketTitle}
                        onChange={(e) => setNewTicketTitle(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <TextField
                        fullWidth
                        variant="outlined"
                        label={translatedata[selectedLanguage].help.message}
                        multiline
                        rows={4}
                        value={newTicketMessage}
                        onChange={(e) => setNewTicketMessage(e.target.value)}
                        sx={{ mb: 2 }}
                    />
                    <Button variant="contained" color="primary" fullWidth onClick={handleCreateTicket}>
                    {translatedata[selectedLanguage].help.send}
                    </Button>
                </Paper>
            </Modal>
        </Container>
        
                <Snackbar
                open={open}
                autoHideDuration={6000}
                onClose={handleCloseSnack}
              >
                <Alert
            onClose={handleCloseSnack}
            severity={stat}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {message}
          </Alert>
        
          </Snackbar>
          <Tutorial tutorialopen={tutorialopen} selectedLanguage={selectedLanguage} setTutorialOpen={setTutorialOpen} tutorialid={tutorialid} setTutorialid={setTutorialid} />
          </>
    );
}

export default App;
