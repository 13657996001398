import React, { useState,useEffect,useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { AppBar, Toolbar, IconButton, Paper, Grid, useMediaQuery, useTheme, Container, Button,Dialog,DialogActions,DialogContent,DialogTitle,TextField     } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import translatedata from '../translate.json';
import { useNavigate } from 'react-router-dom';
import SvgIcon from '@mui/material/SvgIcon';
import axios from 'axios';
import { ReactComponent as LiveCasinoIcon } from '../media/livecasino.svg';
import { ReactComponent as CasinoIcon } from '../media/slot.svg';
import { useCookies } from 'react-cookie';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import CircularProgress from '@mui/material/CircularProgress'; 

const ComingSoon = ({ selectedLanguage,isLoggedIn }) => {
 
  const theme = useTheme();
  const [cookies, setCookie] = useCookies(['serviceToken']);
  const navigate = useNavigate();
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth < 1280);
  const [isMediumScreen, setIsMediumScreen] = useState(window.innerWidth > 1280 && window.innerWidth < 1800);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [activeButton, setActiveButton] = useState('casino');
  const [brands,setBrands]= useState([]);
  const [openedbrands,setOpenedbrands]= useState(false);
  const [fbrands,setFBrands]= useState([]);
  const [games,setGames]= useState([]);
  const [openModal, setOpenModal] = useState(false);
const [selectedGame, setSelectedGame] = useState(null);
const [selectedBrand, setSelectedBrand] = useState(0);
const [searchname, setSearchname] = useState();
const [castitle, setCasTitle] = useState(translatedata[selectedLanguage].zeus.populargame);
const [gameurl, setGameurl] = useState();
const [isFullscreen, setIsFullscreen] = useState(false);
const iframeRef = useRef(null);
const [imageLoaded, setImageLoaded] = useState({});
const [loadinggame, setLoadingGame] = useState(false);
const [imageLoadedBrand,setImageLoadedBrand]= useState({});
useEffect(() => {
  if(isLoggedIn==false){
    navigate("/")
  }
}, [isLoggedIn])

const handleFullscreenToggle = () => {
  if (!document.fullscreenElement) {
    if (iframeRef.current) {
      iframeRef.current.requestFullscreen();
    }
    setIsFullscreen(true);
  } else {
    document.exitFullscreen();
    setIsFullscreen(false);
  }
};
const handleOpenModal = (game) => {
  setOpenModal(true);
  setLoadingGame(true);
  const url = window.location.href;
  const { hostname } = new URL(url);
  const token = cookies.serviceToken;
  const config = {
    headers: { Authorization: `Bearer ${token}` },
  };
  axios.post("https://api.youbetshop.com/api/casino/authplay", {game_id:game.id,callback:hostname,device:isMobile?"mobile":"desktop"}, config)
  .then(response => {
    // if(isMobile){
    //   window.open(response.data.url, '_blank');
    // }else{
      setGameurl(response.data.url)
      setSelectedGame(game);
      setLoadingGame(false);
    

  })
  .catch(error => {
    console.error('Hata', error);

  });

};
const handleCloseModal = () => {
  setOpenModal(false);
  setGameurl();
  setSelectedGame(null);
};
  useEffect(() => {
    const token = cookies.serviceToken;
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    axios.post("https://api.youbetshop.com/api/getallcasinobrands", "", config)
      .then(response => {
        setBrands(response.data.data)
      })
      .catch(error => {
        console.error('Hata', error);
      });
      axios.post("https://api.youbetshop.com/api/get_video_game_list", {attribute:activeButton === 'livecasino' ? '14' : '1'}, config)
      .then(response => {
       
        setGames(response.data.games.slice(0, 30).sort((a, b) => a.popular - b.popular));
      })
      .catch(error => {
        console.error('Hata', error);
      });
  }, [activeButton])
  useEffect(() => {   
    if(activeButton=="casino"){
      const filtredbrands=brands.filter((item)=>item.type==2)
      setFBrands(filtredbrands)
      setCasTitle(translatedata[selectedLanguage].zeus.populargame)
    }else{
      const filtredbrands=brands.filter((item)=>item.type==1)
      setFBrands(filtredbrands)
      setCasTitle(translatedata[selectedLanguage].zeus.populargame)
    }
  }, [brands,activeButton])
  const selectbrand = (id,name)=>{
    setOpenedbrands(!openedbrands)
    setSelectedBrand(id)
    setCasTitle(name)
  }
  useEffect(() => {

    if(selectedBrand !==0 ){
      const token = cookies.serviceToken;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.post("https://api.youbetshop.com/api/get_video_game_list", {brand_id:selectedBrand}, config)
      .then(response => {
        setGames(response.data.games.sort((a, b) => a.popular - b.popular));
      })
      .catch(error => {
        console.error('Hata', error);
      });
    }

  }, [selectedBrand])
  useEffect(() => {
    if(searchname?.length > 3){
      const token = cookies.serviceToken;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.post("https://api.youbetshop.com/api/get_video_game_list", {name:searchname,type_id:activeButton === 'livecasino' ? '1' : '2'}, config)
      .then(response => {
        setGames(response.data.games.slice(0, 30));
      })
      .catch(error => {
        console.error('Hata', error);
      });
    }else if(searchname==""){
      const token = cookies.serviceToken;
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      axios.post("https://api.youbetshop.com/api/get_video_game_list", {attribute:1,type_id:activeButton === 'livecasino' ? '1' : '2'}, config)
      .then(response => {
        setGames(response.data.games.slice(0, 30));
      })
      .catch(error => {
        console.error('Hata', error);
      });
    }

  }, [searchname])

  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back" onClick={() => navigate("/")}>
            <ArrowBackIcon />
            <Typography variant="h2.4" >
              {translatedata[selectedLanguage].zeus.home}
            </Typography>
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>

          </Typography>
          <Typography variant="body1" style={{ marginRight: '10px' }}>
            {"Zeus"}
          </Typography>
        </Toolbar>
      </AppBar>


      <Grid container justifyContent="center" alignItems="center" sx={{ paddingTop: "1%", width: "100%" }}>
        <Grid item xs={11} sm={11} md={10} lg={10}>
          <Typography variant='h4' sx={{ fontWeight: "200", fontSize: isSmallScreen ? "2rem" : "3.5rem", color: "#d79f09", backgroundColor: "#2d2d2db8" }} >{translatedata[selectedLanguage].maincard.Zeus}</Typography>
        </Grid>

        <Grid item xs={11} sm={11} md={10} lg={10}>

          <Grid container sx={{ width: "100%" }}>
          <Grid item xs={12} sm={12} md={2.5} lg={2.5}>
          <Paper elevation={3} style={{ padding: '10px', borderBottom: "solid #d79f09" }}>
          <Grid sx={{borderBottom:"solid 2px #d79f09",paddingBottom:"3px",marginBottom:"5px"}}>
          <Button
                    variant={activeButton === 'casino' ? 'contained' : 'outlined'}
                    onClick={() => setActiveButton('casino')}
                    sx={{ minWidth: "80px",marginRight:"5px",padding:"10px"}}
                    startIcon={
                      <IconButton sx={{ width: "25px", maxHeight: "25px",padding:"0px" }}>
                        <CasinoIcon />
                      </IconButton>
                    }
                  >
                    Casino
                  </Button>

                  <Button
                    variant={activeButton === 'livecasino' ? 'contained' : 'outlined'}
                    onClick={() => setActiveButton('livecasino')}
                    sx={{ minWidth: "80px",padding:"10px" }}
                    startIcon={
                      <IconButton sx={{ width: "25px", maxHeight: "25px",padding:"0px" }}>
                        <LiveCasinoIcon />
                      </IconButton>
                    }
                  >
                    Live Casino
                  </Button>
                  </Grid>
                  {isMobile &&
                  <>
                  <Grid container sx={{ width: "100%" }}>
                  <Button variant={!openedbrands? 'contained' : 'outlined'} fullWidth onClick={()=>setOpenedbrands(!openedbrands)}
                       startIcon={openedbrands?<ExpandLessIcon />:<ExpandMoreIcon />}> {"Sağlayıcılar"}
                      </Button>
                      </Grid>
                                  <TextField id="outlined-basic" label={translatedata[selectedLanguage].zeus.gamesearch} value={searchname} onChange={(e)=>setSearchname(e.target.value)} sx={{marginTop:"8px"} }variant="outlined" fullWidth /></>}
          
                  {openedbrands && isMobile &&
                  fbrands.map((item) => (
  <Grid container key={item.id} textAlign={"center"} alignItems={"center"} display="flex">
    <Grid
      item
      justifyContent={"center"}
      onClick={() => selectbrand(item?.id,item.provider)}
      key={item.id+"n"}
      sx={{
        border: "solid 1px #fff",
        backgroundColor: selectedBrand==item.id?"#676767":"#d79f09",
        borderRadius: "5px",
        marginTop: "5px",
        padding:"2px",
        cursor: "pointer", // Mouse ikonu değişsin
        "&:hover": {
          transform: "scale(1.05)", // Hafif büyüme etkisi
          transition: "transform 0.5s ease-in-out", // Büyüme efektinin yumuşak geçişi
        },
        display: "flex", // Flexbox düzeni kullanımı
        alignItems: "center", // Dikeyde ortalama
        justifyContent: "center", // Yatayda ortalama
      }}
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >
                      {!imageLoadedBrand[item.id] && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '33%',
              color:"#fff",
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          />
        )}
      <img
          src={item.image}
          loading="lazy"
          style={{
            width: '100px',
            opacity: imageLoadedBrand[item.id] ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out', // Animasyonlu geçiş için
          }}
          alt={item.name}
          onLoad={() => setImageLoadedBrand(prevState => ({ ...prevState, [item.id]: true }))}
          onError={() => setImageLoadedBrand(prevState => ({ ...prevState, [item.id]: true }))}
        />
    </Grid>
  </Grid>
))
}
                    {!isMobile&&
                  fbrands.map((item) => (
  <Grid container textAlign={"center"} alignItems={"center"} display="flex">
    <Grid
      item
      justifyContent={"center"}
      onClick={() => selectbrand(item?.id,item.provider)}
      sx={{
        border: "solid 1px #fff",
        backgroundColor: selectedBrand==item.id?"#676767":"#d79f09",
        borderRadius: "5px",
        marginTop: "5px",
        padding:"2px",
        cursor: "pointer", // Mouse ikonu değişsin
        "&:hover": {
          transform: "scale(1.05)", // Hafif büyüme etkisi
          transition: "transform 0.3s ease-in-out", // Büyüme efektinin yumuşak geçişi
        },
        display: "flex", // Flexbox düzeni kullanımı
        alignItems: "center", // Dikeyde ortalama
        justifyContent: "center", // Yatayda ortalama
      }}
      xs={12}
      sm={12}
      md={12}
      lg={12}
    >

      <img
          src={item.image}
          loading="lazy"
          style={{
            width: '100px',
            opacity: imageLoadedBrand[item.id] ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out', // Animasyonlu geçiş için
          }}
          alt={item.name}
          onLoad={() => setImageLoadedBrand(prevState => ({ ...prevState, [item.id]: true }))}
          onError={() => setImageLoadedBrand(prevState => ({ ...prevState, [item.id]: true }))}
        />
    </Grid>
  </Grid>
))
}

          </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={9.5} lg={9.5}>
            <Paper elevation={3} style={{ padding: isMobile?"10px":'20px', borderBottom: "solid #d79f09" }}>
              <Grid container spacing={1} justifyContent="start" >
                <Grid item xs={12} sm={12} md={12} lg={12} >
                  <Box sx={{width:"100%",display:"flex",borderBottom:"solid 2px #d79f09",marginBottom:"15px",paddingBottom:"5px",justifyContent:"space-between"}}>
                  <Typography variant={"h4"}>{castitle}</Typography>
                  {!isMobile&&
                  <TextField id="outlined-basic" label={translatedata[selectedLanguage].zeus.gamesearch} value={searchname} onChange={(e)=>setSearchname(e.target.value)} variant="outlined" />
}
                  </Box>
                  
                {/* <Grid container spacing={2} justifyContent="center">
                <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} >
                <IconButton sx={{ width: isMobile?"100%":"30%",height: "70px", maxHeight: "70px" ,padding:"0px" }}>
                        <LiveCasinoIcon />
                      </IconButton>
                </Grid>
                <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} >
                <IconButton sx={{ width: isMobile?"100%":"30%",height: "70px", maxHeight: "70px" ,padding:"0px" }}>
                        <LiveCasinoIcon />
                      </IconButton>
                </Grid>
                <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} >
                <IconButton sx={{ width: isMobile?"100%":"30%",height: "70px", maxHeight: "70px" ,padding:"0px" }}>
                        <LiveCasinoIcon />
                      </IconButton>
                </Grid>
                <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} >
                <IconButton sx={{ width: isMobile?"100%":"30%",height: "70px", maxHeight: "70px" ,padding:"0px" }}>
                        <LiveCasinoIcon />
                      </IconButton>
                </Grid>
                <Grid item xs={2.4} sm={2.4} md={2.4} lg={2.4} >
                <IconButton sx={{ width: isMobile?"100%":"30%",height: "70px", maxHeight: "70px" ,padding:"0px" }}>
                        <LiveCasinoIcon />
                      </IconButton>
                </Grid>
              </Grid> */}
    <Grid container justifyContent="center" sx={{ textAlign: 'center' }}>
      {games?.map((item) => (
        <Grid
          key={item.id}
          xs={4}
          sm={4}
          md={2}
          lg={2}
          sx={{
            padding: '5px',
            position: 'relative',
            '&:hover img': {
              filter: 'blur(4px)', // Bulanıklık efekti
              transform: 'scale(1.1)',
              transition: 'transform 0.3s ease-in-out, filter 0.3s ease-in-out', // Efektin yumuşak geçişi
            },
            '&:hover .playButton': {
              opacity: 1, // Butonun görünürlüğü
            },
          }}
        >
          <Box sx={{ position: 'relative',backgroundColor:!imageLoaded[item.id]?"#e7e7e7":"#fff",borderRadius:!imageLoaded[item.id]&&"10px",minWidth:"50px",minHeight:isMobile?"100px":"150px" }}>
          {!imageLoaded[item.id] && (
          <CircularProgress
            sx={{
              position: 'absolute',
              top: '50%',
              left: '33%',
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }}
          />
        )}
        <img
          src={item.image}
          loading="lazy"
          style={{
            maxWidth: '180px',
            width: '100%',
            opacity: imageLoaded[item.id] ? 1 : 0,
            transition: 'opacity 0.5s ease-in-out', // Animasyonlu geçiş için
          }}
          alt={item.name}
          onLoad={() => setImageLoaded(prevState => ({ ...prevState, [item.id]: true }))}
          onError={() => setImageLoaded(prevState => ({ ...prevState, [item.id]: true }))}
        />
            <Button
  variant="contained"
  color="primary"
  className="playButton"
  sx={{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    opacity: 0,
    transition: 'opacity 0.3s ease-in-out',
  }}
  onClick={() => handleOpenModal(item)} // Modalı açmak için tıklama etkinliğini ekleyin
>
 {translatedata[selectedLanguage].zeus.playnow}
</Button>
          </Box>
          <Box>
            <Typography sx={{ textAlign: 'center' }}>{item.name}</Typography>
          </Box>
        </Grid>
      ))}
    </Grid>
                </Grid>
              </Grid>

            </Paper>
            </Grid>
          </Grid>

        </Grid>


      </Grid>
      {isMobile==true ?
      openModal==true&&
      <Grid
      container
      sx={{
        width: "100vw", // Ekranın tamamını kaplaması için genişliği %100 viewport genişliği olarak ayarlayın
        height: "100vh", // Ekranın tamamını kaplaması için yüksekliği %100 viewport yüksekliği olarak ayarlayın
        backgroundColor: "#fff",
        zIndex: 9999, // Diğer öğelerin üstünde yer alması için yüksek bir z-index değeri kullanın
        position: "fixed", // Ekranda sabit bir konumda kalması için position'ı "fixed" olarak ayarlayın
        top: 0, // Üstten sıfır piksel uzaklıkta yer alacak şekilde ayarlayın
        left: 0,
        overflow:"hidden" // Soldan sıfır piksel uzaklıkta yer alacak şekilde ayarlayın
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // İçeriği dikeyde ortalamak için kullanılır
          width: "100%",
          height: "5%", // Başlık kısmının yüksekliği
          padding: "0 16px",// Sağ ve soldan boşluk bırakmak için padding ekleyin
          backgroundColor: "#d79f09", // Başlık arka plan rengi
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
          overflow:"hidden"  // Hafif bir gölge efekti ekleyin
        }}
      >
        <Typography>{selectedGame?.name}</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleCloseModal}
          aria-label="close"
        >
          <CloseIcon />
        </IconButton>
      </Box>
      <Grid item xs={12} sm={12} md={12} lg={12} sx={{height: "90%",overflow:"hidden" }}>
      {loadinggame==true?
        <Box sx={{width: "100%", height: "100%",backgroundColor:"#000",justifyContent:"center",alignItems:"center"}}>
        <CircularProgress            sx={{
              position: 'absolute',
              color:"#d79f09",
              top:"45%",
              left:"45%",
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }} />
        </Box>
      :
      <iframe
      ref={iframeRef}
      src={gameurl}
      style={{ width: "100%", height: "100%", border: "none",overflow:"hidden" }} // 50px başlık yüksekliğini çıkartarak iframe yüksekliğini ayarlayın
      title={selectedGame?.name}
    />
      }
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center", // İçeriği dikeyde ortalamak için kullanılır
          width: "100%",
          height: "50px",
          padding: "0 16px", // Sağ ve soldan boşluk bırakmak için padding ekleyin
          backgroundColor: "#d79f09",
          height: "5%", // Başlık arka plan rengi
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)", // Hafif bir gölge efekti ekleyin
        }}
      >
        <Button onClick={handleCloseModal} color="primary">
        {translatedata[selectedLanguage].zeus.close}
        </Button>
        <Button onClick={() => {navigate("/deposit")}} color="primary" variant="contained">
        {translatedata[selectedLanguage].zeus.deposit}
        </Button>
      </Box>
    </Grid>
    
      :
      <Dialog
      open={openModal}
      onClose={handleCloseModal}
      maxWidth="lg"
      fullWidth
    >
      <DialogTitle>
        {selectedGame?.name}
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleCloseModal}
          aria-label="close"
          sx={{ position: 'absolute', top: 8, right: 8 }}
        >
          <CloseIcon />
        </IconButton>
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleFullscreenToggle}
          aria-label="fullscreen"
          sx={{ position: 'absolute', top: 8, right: 50 }}
        >
          {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ padding: 0 }}>
        <Box sx={{ width: '100%', height: '80vh', overflow: 'hidden' }}>
        {loadinggame==true?
        <Box sx={{width: "100%", height: "100%",backgroundColor:"#000",justifyContent:"center",alignItems:"center"}}>
        <CircularProgress            sx={{
              position: 'absolute',
              color:"#d79f09",
              top:"45%",
              left:"45%",
              transform: 'translate(-50%, -50%)',
              zIndex: 1,
            }} />
        </Box>
      :
          <iframe
            ref={iframeRef}
            src={gameurl}
            style={{ width: '100%', height: '100%', border: 'none' }}
            title={selectedGame?.name}
          
          />}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
        {translatedata[selectedLanguage].zeus.close}
        </Button>
        <Button onClick={() => {navigate("/deposit")}} color="primary" variant="contained">
        {translatedata[selectedLanguage].zeus.deposit}
        </Button>
      </DialogActions>
    </Dialog>
    }
    </>
  );
};

export default ComingSoon;
